<template>
  <form class="form" @submit.prevent="save">
    <p class="title is-4 is-valigned is-spaced has-text-white">
      <span class="icon is-medium">
        <i class="fal fa-fingerprint" />
      </span>
      <span>Change your password</span>
    </p>
    <p class="subtitle is-6 has-text-white">
      Please choose a new password. You are required to update your password the
      first time you log in.
    </p>
    <div class="field">
      <label class="label has-text-light">
        <span>New password</span>
        <a
          v-tippy="{ placement: 'right', html: '#password-tooltip' }"
          class="icon is-small has-text-light"
        >
          <i class="fal fa-info-circle" />
        </a>
      </label>
      <password-tooltip id="password-tooltip" style="display: none" />
      <input
        v-validate.disable="{ required: true, min: 8, regex }"
        v-model="password.password"
        type="password"
        name="password"
        class="input"
        :class="{ 'is-danger': errors.has('password') }"
        placeholder="**************"
      />
      <span v-if="errors.first('password')" class="help has-text-light">
        {{ errors.first('password') }}
      </span>
    </div>
    <div class="field">
      <label class="label has-text-light">
        <span>Password confirmation</span>
      </label>
      <input
        v-validate.disable="{ required: true, confirmed: password.password }"
        v-model="passwordConfirmation"
        type="password"
        name="passwordConfirmation"
        data-vv-as="password"
        class="input"
        :class="{ 'is-danger': errors.has('passwordConfirmation') }"
        placeholder="**************"
      />
      <span
        v-if="errors.first('passwordConfirmation')"
        class="help has-text-light"
        >{{ errors.first('passwordConfirmation') }}</span
      >
    </div>
    <div class="field">
      <p class="has-text-light">
        Once set, you will need to log in next time with your new password.
      </p>
    </div>
    <div class="field is-grouped is-grouped-right">
      <button
        class="button is-medium is-info"
        :disabled="$wait.is('save-password')"
        :class="{ 'is-loading': $wait.is('save-password') }"
        type="submit"
      >
        <span>Save</span>
        <span class="icon">
          <i class="fas fa-chevron-circle-right" />
        </span>
      </button>
    </div>
  </form>
</template>

<script>
import PasswordTooltip from 'modules/account/components/password/PasswordTooltip'
import { updatePassword } from 'modules/account/services'
export default {
  name: 'LoginSetPassword',
  components: {
    PasswordTooltip
  },
  props: {
    generatedPassword: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },
  data: () => ({
    password: { plaintext: '', password: '' },
    passwordConfirmation: ''
  }),
  methods: {
    async save() {
      const valid = await this.$validator.validate()
      if (!valid) return

      try {
        this.$wait.start('save-password')
        const headers = { Authorization: 'Bearer ' + this.token }
        await updatePassword({ request: this.password, headers })
        this.$emit('done')
      } finally {
        this.$wait.end('save-password')
      }
    }
  },
  computed: {
    regex() {
      return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()+_\-=}{[\]|:;"/?.><,`~]).{8,}$/g
    }
  },
  mounted() {
    this.password.plaintext = this.generatedPassword
  }
}
</script>
